<template>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-12 col-md-8">
        <h3 class="text-uppercase">Add BioLink</h3>
      </div>
      <div class="col-12 col-md-4 d-flex justify-content-end">
        <router-link to="biolinks">
          <el-button bg class="me-2" text>
            <i class="bi bi-arrow-left-short me-2"></i>
            Cancel
          </el-button>
        </router-link>
        <el-button
          v-if="Link.linkId < 1"
          type="primary"
          @click="generateByteArray()"
          >Add BioLink</el-button
        >
        <el-button v-else type="primary" @click="generateByteArray()"
          >Update BioLink</el-button
        >
      </div>
    </div>
    <el-divider class="mt-1 mb-0"></el-divider>
    <el-container style="height: calc(100vh - 110px)">
      <el-aside width="325px">
        <div class="bio_link_sidebar">
          <el-form label-position="top">
            <el-form-item>
              <template #label>
                <span class="">Title</span>
              </template>
              <el-input v-model="Link.title"></el-input>
            </el-form-item>
            <el-form-item>
              <template #label>
                <span class="">Page</span>
              </template>
              <el-select
                :disabled="Link.linkId > 0"
                v-model="Link.customUrl"
                class="w-100"
                style="width: 250px"
                @change="updateQrcode = new Date()"
              >
                <el-option
                  v-for="domain in domains"
                  :key="domain.id"
                  :label="domain.name"
                  :value="domain.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <template #label>
                <span class="">Slug</span>
              </template>
              <el-input
                :disabled="Link.linkId > 0"
                @keyup="updateQrcode = new Date()"
                v-model="Link.linkGuid"
                @keydown.space.prevent
              ></el-input>
            </el-form-item>
            <el-form-item>
              <template #label>
                <span class="">Tab Title</span>
              </template>
              <el-input v-model="Link.bioLink.tabTitle"></el-input>
            </el-form-item>
            <!-- <el-form-item>
              <template #label>
                <span class="">Page Desc</span>
              </template>
              <el-input
                type="textarea"
                :rows="8"
                v-model="Link.bioLink.pageDescription"
              ></el-input>
            </el-form-item> -->
            <el-form-item>
              <template #label>
                <span class="">Select Pixel</span>
              </template>
              <el-select
                v-model="localPixelCodes"
                multiple
                placeholder="Select pixel"
                @change="checkPixel()"
              >
                <el-option
                  v-for="code in pixelCodes"
                  :key="code.id"
                  :label="code.codeName"
                  :value="code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <template #label>
                <span class="">Select Font</span>
              </template>
              <el-select
                v-model="Link.bioLink.fontFamily"
                placeholder="Select font"
                @change="changeFont()"
              >
                <el-option
                  v-for="font in fontsList"
                  :key="font.value"
                  :label="font.text"
                  :value="font.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <template #label>
                <span class="">Background Theme Color</span>
              </template>
              <div
                style="overflow-x: auto; display: flex; align-items: stretch"
              >
                <div
                  style="
                    flex: 0 0 auto;
                    background: #e9eff7;
                    border-radius: 3px;
                    width: 80px !important;
                    height: 50px !important;
                    cursor: pointer;
                  "
                  @click="applyColor('#f8f8fb')"
                ></div>
                <div
                  style="
                    flex: 0 0 auto;
                    background: #fff3bd;
                    border-radius: 3px;
                    width: 80px !important;
                    height: 50px !important;
                    cursor: pointer;
                    margin-left: 3px;
                  "
                  @click="applyColor('#fff3bd')"
                ></div>
                <div
                  style="
                    flex: 0 0 auto;
                    background: #d4f6e6;
                    border-radius: 3px;
                    width: 80px !important;
                    height: 50px !important;
                    cursor: pointer;
                    margin-left: 3px;
                  "
                  @click="applyColor('#d4f6e6')"
                ></div>
                <div
                  style="
                    flex: 0 0 auto;
                    background: #e8d3de;
                    border-radius: 3px;
                    width: 80px !important;
                    height: 50px !important;
                    cursor: pointer;
                    margin-left: 3px;
                  "
                  @click="applyColor('#e8d3de')"
                ></div>
                <div
                  style="
                    flex: 0 0 auto;
                    background: #20c997;
                    border-radius: 3px;
                    width: 80px !important;
                    height: 50px !important;
                    cursor: pointer;
                    margin-left: 3px;
                  "
                  @click="applyColor('#20c997')"
                ></div>
                <div
                  style="
                    flex: 0 0 auto;
                    background: #369f9b;
                    border-radius: 3px;
                    width: 80px !important;
                    height: 50px !important;
                    cursor: pointer;
                    margin-left: 3px;
                  "
                  @click="applyColor('#369f9b')"
                ></div>
                <div
                  style="
                    flex: 0 0 auto;
                    background: #2f2f2f;
                    border-radius: 3px;
                    width: 80px !important;
                    height: 50px !important;
                    cursor: pointer;
                    margin-left: 3px;
                  "
                  @click="applyColor('#2f2f2f')"
                ></div>
                <div
                  style="
                    flex: 0 0 auto;
                    background: #000000;
                    border-radius: 3px;
                    width: 80px !important;
                    height: 50px !important;
                    cursor: pointer;
                    margin-left: 3px;
                  "
                  @click="applyColor('#000000')"
                ></div>
              </div>
            </el-form-item>
            <el-form-item>
              <!--              <el-input v-model="color" disabled></el-input>-->
              <div class="row justify-content-between w-100 text-dark">
                <div class="col-12 col-md-6">
                  <span class="text-caption">Background Color</span>
                  <div class="el-div">
                    <span>{{ Link.bioLink.backgroundColor }}</span>
                    <el-color-picker
                      v-model="Link.bioLink.backgroundColor"
                      @change="changeBgColor"
                    />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <span class="text-caption">Text Color</span>
                  <div class="el-div">
                    <span>{{ Link.bioLink.fontColor }}</span>
                    <el-color-picker
                      v-model="Link.bioLink.fontColor"
                      @change="changeTxtColor()"
                    />
                  </div>
                </div>
              </div>
            </el-form-item>
            <el-form-itam>
              <div class="row p-0 justify-content-between w-100 text-dark">
                <div class="col-12 col-md-6">
                  <span>Verified Icon</span>
                  <div class="text-caption">
                    <el-radio-group
                      v-model="Link.bioLink.verifiedIcon"
                      fill="#3e8c3c"
                      size="small"
                      text-color="#fff"
                    >
                      <el-radio-button :label="true">Show</el-radio-button>
                      <el-radio-button :label="false">Hide</el-radio-button>
                    </el-radio-group>
                  </div>
                </div>
                <!-- <div class="col-12 col-md-6">
                  <span>Share Button</span>
                  <div class="text-caption w-100">
                    <el-radio-group
                      v-model="shareBtn"
                      fill="#3e8c3c"
                      size="small"
                      text-color="#fff"
                    >
                      <el-radio-button :label="true">Show</el-radio-button>
                      <el-radio-button :label="false">Hide</el-radio-button>
                    </el-radio-group>
                  </div>
                </div> -->
              </div>
            </el-form-itam>
          </el-form>
        </div>
      </el-aside>
      <el-main class="px-0 py-0">
        <div class="d-flex justify-content-start py-2">
          <el-button type="primary" class="ms-1" @click="previewPage()">
            <i class="bi bi-display"></i>
            <span class="ms-1"> Preview </span>
          </el-button>
        </div>
        <el-divider border-style="dashed" class="my-0"></el-divider>
        <div class="my-2"></div>
        <div
          :style="{
            fontFamily: Link.bioLink.fontFamily,
            backgroundColor: Link.bioLink.backgroundColor,
            color: Link.bioLink.fontColor,
          }"
          style="
            min-height: calc(100vh - 180px);
            width: 100%;
            max-width: 100%;
            margin: auto;
            padding-top: 30px;
            display: flex;
            flex-wrap: wrap;
            position: relative;
          "
        >
          <div class="container">
            <div class="row justify-content-center align-items-center">
              <div
                class="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center"
              >
                <div class="d-flex pt-5 avatar" style="position: relative">
                  <div class="">
                    <img
                      width="120"
                      height="120"
                      style="border-radius: 50%"
                      v-if="isBase64Image(Link.bioLink.avatar)"
                      :src="Link.bioLink.avatar"
                      alt=""
                    />
                    <input
                      type="file"
                      id="avatar"
                      accept="image/*"
                      class="d-none"
                      :key="fileInputKey"
                      ref="uploader1"
                      @change="onFileChanged('avatar')"
                    />
                    <el-button
                      style="
                        width: 120px !important;
                        height: 120px !important;
                        border-radius: 50%;
                        border: 1px dashed gray;
                      "
                      v-if="!isBase64Image(Link.bioLink.avatar)"
                      @click="handleFileImport('avatar')"
                      bg
                      text
                    >
                      <i class="bi bi-plus"></i>
                    </el-button>
                    <el-button
                      style="position: absolute; top: 34px; left: -22%"
                      circle
                      type="info"
                      class="ms-2"
                      size="small"
                      v-if="isBase64Image(Link.bioLink.avatar)"
                      @click="Link.bioLink.avatar = ''"
                    >
                      <i class="bi bi-x"></i>
                    </el-button>
                  </div>
                  <div
                    style="
                      width: 26px;
                      height: 26px;
                      position: absolute;
                      top: 20%;
                      right: -10px;
                    "
                  >
                    <button
                      v-if="Link.bioLink.verifiedIcon"
                      style="
                        height: 25px;
                        width: 25px;
                        border: 0;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: #fff;
                        box-shadow: rgba(0, 0, 0, 0.35) 0 3px 5px;
                        margin: 0 10px 0 10px;
                      "
                    >
                      <img
                        width="20"
                        src="https://www.cdnly.org/asset/cblue.png"
                        alt="verified"
                      />
                    </button>
                  </div>
                </div>
                <div
                  style="max-width: 450px; min-width: 450px; text-align: center"
                >
                  <input
                    placeholder="Page title"
                    type="text"
                    class="w-100 page-input pg-head"
                    v-model="Link.bioLink.pageTitle"
                  />
                </div>
                <div
                  style="max-width: 450px; min-width: 450px; text-align: center"
                >
                  <input
                    placeholder="Description goes here...."
                    type="text"
                    class="w-100 page-input pg-desc"
                    v-model="Link.bioLink.pageDescription"
                  />
                </div>
                <draggable
                  :list="Link.bioLink.blockElements"
                  class="list-group mt-3"
                  ghost-class="ghost"
                  @start="dragging = true"
                  @end="dragging = false"
                >
                  <template #item="{ element }">
                    <div
                      v-if="element.isLink"
                      class="content-container"
                      style="margin-top: 15px; margin-bottom: 15px"
                    >
                      <button class="btn btn-sm edit-btn1 mbtn-primary">
                        <i class="mgc_dots_fill text-white"></i>
                      </button>

                      <span>{{ element.buttonTitle }}</span>

                      <el-dropdown trigger="click" class="edit-btn2">
                        <button class="btn btn-sm mbtn-primary">
                          <i class="mgc_settings_1_line text-white"></i>
                        </button>
                        <template #dropdown>
                          <el-dropdown-menu>
                            <el-dropdown-item
                              @click="editBlockElement(element)"
                            >
                              <div class="d-flex align-items-center">
                                <i class="ms-1 mgc_edit_2_line"></i>
                                <span class="ms-2">Edit</span>
                              </div>
                            </el-dropdown-item>
                            <el-divider
                              class="my-0"
                              border-style="dashed"
                            ></el-divider>
                            <el-dropdown-item
                              @click="deleteBlockElement(element)"
                            >
                              <div class="d-flex align-items-center">
                                <i class="ms-1 mgc_delete_2_line"></i>
                                <span class="ms-2">Remove</span>
                              </div>
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown>
                    </div>
                    <div
                      v-else
                      class="content-container-text"
                      style="margin-top: 15px; margin-bottom: 15px"
                    >
                      <button class="btn btn-sm edit-btn1 mbtn-primary">
                        <i class="mgc_dots_fill text-white"></i>
                      </button>
                      <div v-html="element.content"></div>
                      <el-dropdown trigger="click" class="edit-btn2">
                        <button class="btn btn-sm mbtn-primary">
                          <i class="mgc_settings_1_line text-white"></i>
                        </button>
                        <template #dropdown>
                          <el-dropdown-menu>
                            <el-dropdown-item
                              @click="editBlockElement(element)"
                            >
                              <div class="d-flex align-items-center">
                                <i class="ms-1 mgc_edit_2_line"></i>
                                <span class="ms-2">Edit</span>
                              </div>
                            </el-dropdown-item>
                            <el-divider
                              class="my-0"
                              border-style="dashed"
                            ></el-divider>
                            <el-dropdown-item
                              @click="deleteBlockElement(element)"
                            >
                              <div class="d-flex align-items-center">
                                <i class="ms-1 mgc_delete_2_line"></i>
                                <span class="ms-2">Remove</span>
                              </div>
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown>
                    </div>
                  </template>
                </draggable>
                <el-button
                  size="large"
                  type="primary"
                  class="mt-5"
                  @click="elementDialog = true"
                >
                  <i class="bi bi-plus-lg me-1"></i>
                  Add Block Element
                </el-button>
              </div>
            </div>
          </div>

          <!-- <div class="d-flex head" style="position: relative">
            <div class="position-relative pt-5 avatar">
              <img
                width="120"
                height="120"
                style="border-radius: 50%"
                v-if="isBase64Image(Link.bioLink.avatar)"
                :src="Link.bioLink.avatar"
                alt=""
              />
              <input
                type="file"
                id="avatar"
                accept="image/*"
                class="d-none"
                :key="fileInputKey"
                ref="uploader1"
                @change="onFileChanged('avatar')"
              />
              <el-button
                style="
                  width: 120px !important;
                  height: 120px !important;
                  border-radius: 50%;
                  border: 1px dashed gray;
                "
                v-if="!isBase64Image(Link.bioLink.avatar)"
                @click="handleFileImport('avatar')"
                bg
                text
              >
                <i class="bi bi-plus"></i>
              </el-button>
              <el-button
                style="position: absolute; top: 10%; right: 10%"
                circle
                type="info"
                class="ms-2"
                size="small"
                v-if="isBase64Image(Link.bioLink.avatar)"
                @click="Link.bioLink.avatar = ''"
              >
                <i class="bi bi-x"></i>
              </el-button>
            </div>
            <div style="width: 26px; height: 26px">
              <button
                v-if="Link.bioLink.verifiedIcon"
                style="
                  height: 25px;
                  width: 25px;
                  border: 0;
                  border-radius: 50%;
                  position: absolute;
                  top: 20%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: #fff;
                  box-shadow: rgba(0, 0, 0, 0.35) 0 3px 5px;
                  margin: 0 10px 0 10px;
                "
              >
                <img
                  width="20"
                  src="https://www.cdnly.org/asset/cblue.png"
                  alt="verified"
                />
              </button>
            </div>
          </div> -->

          <div id="page" class="d-none"></div>
        </div>
      </el-main>
    </el-container>

    <!--    Element Dialog-->
    <el-dialog
      v-model="elementDialog"
      draggable
      title="Add Block Element"
      width="450"
    >
      <div class="">
        <span class="mb-2 d-block">Link blocks</span>
        <button class="blockBtn" @click="showDialog(3)">
          <i class="bi bi-link-45deg me-3"></i>
          Add/Edit Popular Social Links
        </button>
        <span class="mb-2 d-block">Contains</span>
        <button class="blockBtn" @click="showDialog(1)">
          <i class="bi bi-type-h2 me-3"></i>
          Heading Title
        </button>
        <button class="blockBtn" @click="showDialog(2)">
          <i class="bi bi-paragraph me-3"></i>
          Paragraph
        </button>
        <span class="mb-2 d-block">Flux & integration</span>
        <button class="blockBtn" @click="showDialog(4)">
          <i class="bi bi-tiktok me-3"></i>
          Tiktok Wall
        </button>
        <button class="blockBtn" @click="showDialog(5)">
          <i class="bi bi-instagram me-3"></i>
          Add Instagram Feed Wall
        </button>
      </div>
    </el-dialog>

    <el-dialog v-model="linkDialog" width="500">
      <template #header>
        <h5>Add Custom Link</h5>
      </template>
      <template #default>
        <el-form label-position="top">
          <div class="row">
            <div class="col-12">
              <el-form-item label="Enter destination URL">
                <el-input
                  class="text-caption"
                  v-model="blockElement.link"
                  placeholder="https://"
                ></el-input>
              </el-form-item>
            </div>

            <div class="col-12 col-md-2">
              <el-form-item label="Image">
                <div class="position-relative">
                  <img
                    width="50"
                    height="50"
                    v-if="isBase64Image(blockElement.logo)"
                    :src="blockElement.logo"
                    alt="Logo"
                  />
                  <input
                    type="file"
                    id="button"
                    accept="image/*"
                    class="d-none"
                    :key="fileInputKey"
                    ref="uploader2"
                    @change="onFileChanged('button')"
                  />

                  <el-button
                    style="
                      width: 50px !important;
                      height: 50px !important;
                      border: 1px dashed gray;
                    "
                    v-if="!isBase64Image(blockElement.logo)"
                    @click="handleFileImport('button')"
                    bg
                    text
                  >
                    <i class="bi bi-plus"></i>
                  </el-button>
                  <el-button
                    style="position: absolute; top: -5px; right: -20px"
                    v-if="isBase64Image(blockElement.logo)"
                    @click="blockElement.logo = ''"
                    bg
                    text
                    circle
                    size="small"
                  >
                    <i class="bi bi-x"></i>
                  </el-button>
                </div>
              </el-form-item>
            </div>
            <div class="col-12 col-md-10">
              <el-form-item label="Button Title">
                <el-input
                  v-model="blockElement.buttonTitle"
                  placeholder="View Link"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-12">
              <el-form-item label="Style">
                <div class="row w-100 bg-body-tertiary py-3 pb-4">
                  <div class="col-12 col-md-6 d-flex flex-column w-100">
                    <div class="row">
                      <div class="col-6">
                        <label class="text-caption">Background Color </label>
                        <el-input
                          class="text-caption"
                          v-model="blockElement.backgroundColor"
                          disabled
                        >
                          <template #suffix>
                            <el-color-picker
                              v-model="blockElement.backgroundColor"
                            ></el-color-picker>
                          </template>
                        </el-input>
                      </div>
                      <div class="col-6">
                        <label class="text-caption">Color </label>
                        <el-input
                          class="text-caption"
                          v-model="blockElement.color"
                          disabled
                        >
                          <template #suffix>
                            <el-color-picker
                              v-model="blockElement.color"
                            ></el-color-picker>
                          </template>
                        </el-input>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 mt-2">
                    <el-select
                      v-model="blockElement.fontFamily"
                      placeholder="Select font"
                    >
                      <el-option
                        v-for="font in fontsList"
                        :key="font.value"
                        :label="font.text"
                        :value="font.value"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="linkDialog = false">Cancel</el-button>
          <el-button
            type="primary"
            class="ms-2"
            @click="addCustomLink(blockElement)"
            >Add Link</el-button
          >
        </div>
      </template>
    </el-dialog>

    <el-dialog v-model="tiktokField" title="Tiktok Wall" width="400">
      <el-form label-position="top">
        <el-form-item label="Enter Tiktok userId">
          <el-input v-model="blockElement.link"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button bg text @click="tiktokField = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="addTikTokEmbed(), (tiktokField = false)"
          >Save</el-button
        >
      </template>
    </el-dialog>
    <el-dialog v-model="instagramField" title="Instagram Wall" width="350">
      <el-form label-position="top">
        <el-form-item label="Enter Instagram username">
          <el-input v-model="blockElement.link"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button bg text @click="instagramField = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="addInstagramProfile(), (instagramField = false)"
          >Save</el-button
        >
      </template>
    </el-dialog>
    <el-dialog v-model="headingField" title="Add Heading Title" width="350">
      <el-form label-position="top">
        <el-form-item label="Enter heading">
          <el-input
            type="textarea"
            :rows="10"
            v-model="blockElement.headingTitle"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button bg text @click="headingField = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="addHeadingText(blockElement, 1), (headingField = false)"
          >Save
        </el-button>
      </template>
    </el-dialog>
    <el-dialog
      v-model="paragraphField"
      title="Add Page Description or Paragraph"
      width="400"
    >
      <el-form label-position="top">
        <el-form-item label="Enter paragraph text">
          <el-input
            type="textarea"
            :rows="10"
            v-model="blockElement.headingTitle"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button bg text @click="paragraphField = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="addHeadingText(blockElement, 2), (paragraphField = false)"
          >Save
        </el-button>
      </template>
    </el-dialog>
    <div class="d-none">
      <div ref="qrcodeContainer">
        <QRCodeVue3
          ref="qrcode"
          :width="150"
          :height="150"
          :value="Link.customUrl + Link.linkGuid"
          :key="updateQrcode"
          :qrOptions="{
            typeNumber: 0,
            mode: 'Byte',
            errorCorrectionLevel: 'H',
          }"
          :imageOptions="{
            hideBackgroundDots: true,
            imageSize: 0.4,
            margin: 0,
          }"
          :dotsOptions="{
            type: 'dots',
            color: '#26249a',
            gradient: {
              type: 'linear',
              rotation: 0,
              colorStops: [
                { offset: 0, color: '#000' },
                { offset: 1, color: '#000' },
              ],
            },
          }"
          v-bind:image="Link.logo"
          :backgroundOptions="{ color: '#ffffff' }"
          :cornersSquareOptions="{ type: 'extra-rounded', color: '#000000' }"
          :cornersDotOptions="{ type: 'square', color: '#000000' }"
          fileExt="png"
          myclass="my-qur"
          imgclass="img-qr"
          downloadButton="btn btn-primary"
          :downloadOptions="{ name: 'vqr', extension: 'webp' }"
        />
      </div>
    </div>

    <el-dialog v-model="textDialog" draggable width="400">
      <template #header>
        <h5>{{ textDialogText }}</h5>
      </template>
      <template #default>
        <el-form label-position="top">
          <el-input
            v-model="blockElement.headingTitle"
            type="textarea"
            :rows="15"
          ></el-input>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="textDialog = false">Cancel</el-button>
          <el-button
            type="primary"
            class="ms-2"
            v-if="textDialogText.includes('Heading')"
            @click="addHeadingText(blockElement, 1)"
            >Add Heading
          </el-button>
          <el-button
            type="primary"
            class="ms-2"
            v-else
            @click="addHeadingText(blockElement, 2)"
            >Add Description
          </el-button>
        </div>
      </template>
    </el-dialog>

    <div v-if="loading" class="spinner-border d-loader biolink_loader"></div>
  </div>
</template>
<!--suppress JSObjectNullOrUndefined -->
<script>
import Config from "@/Configuration/Config";
import domainService from "@/Services/domainService";
import store from "@/store";
import pixelCodeService from "@/Services/pixelCodeService";
import linkService from "@/Services/linkService";
import qrCodeService from "@/Services/linkService";
import { ShowMessage } from "@/Utility/Utility";
import QRCodeVue3 from "qrcode-vue3";
import draggable from "vuedraggable";
export default {
  name: "AddBioLink",
  components: { QRCodeVue3, draggable },
  data() {
    return {
      loading: false,
      drag: false,
      elementDialog: false,
      textDialog: false,
      linkDialog: false,
      textDialogText: "Add Heading",
      verifiedIcon: true,
      shareBtn: true,
      textColor: "#000",
      tiktokField: false,
      socialButtonDialog: false,
      blockElement: {
        id: 0,
        elementType: 0,
        headingTitle: "",
        buttonTitle: "",
        fontFamily: "Poppins",
        color: "#000000",
        isLink: false,
        link: "",
        backgroundColor: "#ffffff",
        logo: "",
        content: "",
      },
      blockElements: [],
      socialButtonItem: {
        color: "#0d6efd",
        text: "",
        link: "",
        logo: "",
        content: "",
      },
      tiktokFieldText: "",
      instagramField: false,
      socialLinksDialog: false,
      headingField: false,
      headingFieldText: "",
      paragraphField: false,
      paragraphFieldText: "",
      instagramUsernameTxt: "",
      color: "#000",
      domains: [],
      pixelCodes: [],
      localPixelCodes: [],
      pageTitle: "",
      pageDescription: "",
      fontFamily: "Poppins",
      fontsList: [
        { text: "Poppins", value: "Poppins" },
        { text: "Quicksand", value: "Quicksand" },
        { text: "Noto Sans", value: "Noto Sans" },
        { text: "Saira Condensed", value: "Saira Condensed" },
        { text: "Space Grotesk", value: "Space Grotesk" },
        { text: "Josefin Sans", value: "Josefin Sans" },
        { text: "Montserrat", value: "Montserrat" },
      ],
      pageDesc: "",

      updateQrcode: new Date(),
      Link: {
        linkId: 0,
        logo: "",
        userId: Number(sessionStorage.getItem("userId")),
        linkGuid: "",
        htmlContent: "",
        title: "",
        imageLink: "",
        linkType: 40,
        link: "",
        customUrl: Config.apiBaseUrl,
        pixelCode: "",
        pixelCodes: [],
        domainName: Config.apiBaseUrl,
        name: Config.apiBaseUrl,
        bioLink: {
          id: 0,
          content: "",
          pageTitle: "",
          tabTitle: "",
          avatar: "",
          image: "",
          logo: "",
          pageDescription: "",
          fontFamily: "Poppins",
          fontSize: "",
          fontColor: "#000",
          backgroundColor: "#f8f8fb",
          pixelCode: "",
          verifiedIcon: true,
          shareButton: true,
          blockElements: [],
          socialButtons: [],
        },
      },
      fileInputKey: new Date(),
      filter: {
        pageSize: 25,
        page: 1,
        filterText: "",
        isVerified: true,
        userId: sessionStorage.getItem("userId"),
        isActive: true,
      },
    };
  },
  methods: {
    showDialog(type) {
      this.blockElement = type;
      switch (type) {
        case 1:
          this.headingField = true;
          break;
        case 2:
          this.paragraphField = true;
          break;
        case 3:
          this.linkDialog = true;
          break;
        case 4:
          this.tiktokField = true;
          break;
        case 5:
          this.instagramField = true;
          break;
      }
      this.elementDialog = false;
      this.resetBlockElement();
    },
    getWindowHeight() {
      return window.innerHeight + "px";
    },
    removeBlockElement(id) {
      const index = this.Link.bioLink.blockElements.findIndex(
        (element) => element.id === id
      );
      if (index !== -1) {
        this.Link.bioLink.blockElements.splice(index, 1);
      }
    },
    addCustomLink(blockElement) {
      let index = 0;
      blockElement.isLink = true;
      blockElement.elementType = 3;
      const existingElement = this.Link.bioLink.blockElements.find(
        (element) => element.id === this.blockElement.id
      );
      if (existingElement !== undefined) {
        index = this.Link.bioLink.blockElements.findIndex(
          (element) => element.id === this.blockElement.id
        );
      }
      if (!this.blockElement.link) {
        ShowMessage("error", "Please enter destination URL");
        return;
      }
      if (!this.blockElement.buttonTitle) {
        ShowMessage("error", "Please enter button title");
        return;
      }
      let content = undefined;
      if (this.isBase64Image(blockElement.logo)) {
        content = `<a href="${blockElement.link}" target="_blank" style="text-decoration: none; width: 100%;margin-top: 8px; margin-bottom: 8px;">
              <button style="border: 0;min-height: 62.4px; width: 100%; display: flex;  align-items: center; outline: none; padding:0 12px; font-size: 17px;box-shadow: rgba(0, 0, 0, .15) 0 1px 5px; border-radius: 5px;font-family: ${blockElement.fontFamily}; cursor: pointer; color:${blockElement.color};background: ${blockElement.backgroundColor}; transition: transform 0.15s cubic-bezier(0,.2,.5,3) 0s;">
                  <img width="30px" height="30px" style="margin-right: 5px"  src="${blockElement.logo}" alt="Logo" />
                  <div style="display: flex; justify-content: center; align-items: center; width: 100%;position: absolute; left: 0">
                      <span style="font-size: 17px; font-weight: 500"> ${blockElement.buttonTitle}</span>
                  </div>
              </button>
          </a>
          <style>
              a button:hover {
                  transform: scale(1.04);
              }
          </style>`;
      } else {
        content = `<a href="${blockElement.link}" target="_blank" style="text-decoration: none;width: 100%;margin-top: 8px; margin-bottom: 8px;">
            <button style="border: 0;min-height: 62.4px;width: 100%; display: flex;  align-items: center;box-shadow: rgba(0, 0, 0, .15) 0 1px 5px; outline: none; padding:0 12px; font-size: 17px; border-radius: 5px;font-family: ${blockElement.fontFamily}; cursor: pointer; color:${blockElement.color};background: ${blockElement.backgroundColor}; transition: transform 0.15s cubic-bezier(0,.2,.5,3) 0s;">
             <div style="display: flex; justify-content: center; align-items: center; width: 100%; ">
              <span style="font-size: 17px; font-weight: 500"> ${blockElement.buttonTitle}</span>
            </div>
            </button>
        </a>
        <style>
              a button:hover {
                  transform: scale(1.04);
              }
          </style>`;
      }
      if (this.blockElement.id > 0) {
        this.Link.bioLink.blockElements[index].content = content;
        this.Link.bioLink.blockElements[index].color = blockElement.color;
        this.Link.bioLink.blockElements[index].fontFamily =
          blockElement.fontFamily;
        this.Link.bioLink.blockElements[index].backgroundColor =
          blockElement.backgroundColor;
        this.Link.bioLink.blockElements[index].logo = blockElement.logo;
        // You can update other properties here if needed
      } else {
        // Add new element
        this.blockElement.content = content;
        this.blockElement.id =
          this.Link.bioLink.blockElements.reduce(
            (max, element) => (element.id > max ? element.id : max),
            0
          ) + 1;
        this.Link.bioLink.blockElements.push(this.blockElement);
      }
      // Clear the input field
      this.linkDialog = false;
      this.resetBlockElement();
    },
    editBlockElement(blockElement) {
      this.blockElement = JSON.parse(JSON.stringify(blockElement));
      console.log(this.blockElement);
      switch (this.blockElement.elementType) {
        case 1:
          this.headingField = true;
          break;
        case 2:
          this.paragraphField = true;
          break;
        case 3:
          this.linkDialog = true;
          break;
        case 4:
          this.tiktokField = true;
          break;
        case 5:
          this.instagramField = true;
          break;
      }
    },
    addHeadingText(blockElement, type) {
      const existingElement = this.Link.bioLink.blockElements.find(
        (element) => element.id === blockElement.id
      );
      blockElement.elementType = type;
      console.log(existingElement, type, blockElement.headingTitle);
      switch (type) {
        case 1:
          blockElement.content = `<span style="font-size: 22px;display: flex;
    justify-content: center;
    align-items: center;min-height: 62.4px; text-decoration: none;  width: 100%; margin-top: 8px; margin-bottom: 8px; font-weight:bold;color: #000;  background-color: transparent">${blockElement.headingTitle}</span>`;
          break;
        case 2:
          blockElement.content = `<span style="color:#000;min-height: 62.4px;    text-decoration: none;
    width: 100%;margin-top: 8px; margin-bottom: 8px; display: flex;
    justify-content: center;
    align-items: center;background-color:transparent">${blockElement.headingTitle}</span>`;
          break;
      }
      if (existingElement) {
        // Update existing element
        existingElement.headingTitle = blockElement.headingTitle;
        existingElement.content = blockElement.content;
        // You can update other properties here if needed
      } else {
        // Add new element
        console.log(blockElement);
        blockElement.id =
          this.Link.bioLink.blockElements.reduce(
            (max, element) => (element.id > max ? element.id : max),
            0
          ) + 1;
        this.Link.bioLink.blockElements.push(blockElement);
      }
      this.textDialog = false;
    },
    resetBlockElement() {
      this.blockElement = {
        id: 0,
        elementType: 0,
        headingTitle: "",
        buttonTitle: "",
        fontFamily: "Poppins",
        color: "#000000",
        isLink: false,
        link: "",
        backgroundColor: "#ffffff",
        logo: "",
        content: "",
      };
    },
    logHtmlContent() {
      let htmlContent = `<!DOCTYPE html>
                <html lang="en">
                <head>
                  <meta charset="UTF-8">
                  <title>${this.Link.bioLink.tabTitle}</title>
                  <meta name="viewport" content="width=device-width, initial-scale=1">
                <link rel="preconnect" href="https://fonts.googleapis.com">
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Saira+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Space+Grotesk:wght@300..700&display=swap" rel="stylesheet">
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous">
                </head>
                <body style="display: flex;flex-direction: column; justify-content: center; align-items: center; font-family: ${this.Link.bioLink.fontFamily}; background-color: ${this.Link.bioLink.backgroundColor};color: ${this.Link.bioLink.backgroundColor};">


                `;
      htmlContent +=
        "<scr" +
        'ipt async="" src="https://www.tiktok.com/embed.js"></scr' +
        "ipt>";
      if (
        this.isBase64Image(this.Link.bioLink.avatar) &&
        this.Link.bioLink.verifiedIcon
      ) {
        htmlContent += `<div style="position: relative"><img src="${this.Link.bioLink.avatar}" alt="Profile Avatar" style="height: 120px; width: 120px; border-radius: 50%;margin-top: 30px">\n
          <div style="width: 26px; height: 26px; position: absolute;
                  top: 10%;; right: -10px">
              <button
                v-if="verifiedIcon"
                style="
                  height: 25px;
                  width: 25px;
                  border: 0;
                  border-radius: 50%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: #fff;
                  box-shadow: rgba(0, 0, 0, 0.35) 0 3px 5px;
                  margin: 0 10px 0 10px;
                "
              >
                <img width="20" src="https://www.cdnly.org/asset/cblue.png" alt="verified">
              </button>
            </div>          
          </div>`;
      } else if (
        !this.isBase64Image(this.Link.bioLink.avatar) &&
        this.Link.bioLink.verifiedIcon
      ) {
        htmlContent += `<div style="position: relative">\n
          <div style="width: 26px; height: 26px; position: absolute;
                  top: 10%;; right: -10px">
              <button
                style="
                  height: 25px;
                  width: 25px;
                  border: 0;
                  border-radius: 50%;
                  right: -35%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: #fff;
                  box-shadow: rgba(0, 0, 0, 0.35) 0 3px 5px;
                  margin: 0 10px 0 10px;
                "
              >
                <img width="20" src="https://www.cdnly.org/asset/cblue.png" alt="verified">
              </button>
            </div>          
          </div>`;
      } else if (
        this.isBase64Image(this.Link.bioLink.avatar) &&
        !this.Link.bioLink.verifiedIcon
      ) {
        htmlContent += `<div style="position: relative">
          <img src="${this.Link.bioLink.avatar}" alt="Profile Avatar" style="height: 120px; width: 120px; border-radius: 50%;margin-top: 30px">\n       
          </div>`;
      }

      if (this.Link.bioLink.pageTitle && this.Link.bioLink.pageDescription) {
        htmlContent += `<div class="container-fluid">
                <div class="row justify-content-center">
                  <div class="col-12 col-sm-8 col-md-8 col-lg-4 d-flex flex-column jutify-content-center align-items-center">
                    <div style="margin-bottom:10px">
                      <div style="
                       font-size: 20px;
                            font-weight: bold;
                            text-align: center !important;
                             padding-top: 18px;
                            padding-bottom: 8px;
                            background-color: transparent !important;
                            color: ${this.Link.bioLink.fontColor};
                            font-family:  ${this.Link.bioLink.fontFamily};
                            ">
                          ${this.Link.bioLink.pageTitle}
                          </div>
                           <div style="
                           font-size: 17px;
                            text-align: center !important;
                            outline: none;
                            padding-bottom: 15px;
                            background-color: transparent;
                            color: ${this.Link.bioLink.fontColor};
                            font-family:  ${this.Link.bioLink.fontFamily};">
                      ${this.Link.bioLink.pageDescription}
                      </div>
                      </div>
        `;
      } else if (
        this.Link.bioLink.pageTitle &&
        !this.Link.bioLink.pageDescription
      ) {
        htmlContent += `<div class="container-fluid">
                <div class="row justify-content-center">
                  <div class="col-12 col-sm-8 col-md-8 col-lg-4 d-flex flex-column jutify-content-center align-items-center">
                    <div style="margin-bottom:10px">
                      <div style="
                        font-size: 20px;
                            font-weight: bold;
                            text-align: center !important;
                            padding-top: 18px;
                            padding-bottom: 8px;
                            background-color: transparent !important;
                            color: ${this.Link.bioLink.fontColor};
                            font-family:  ${this.Link.bioLink.fontFamily};
                            ">
                          ${this.Link.bioLink.pageTitle}
                          </div>
                      </div>
        `;
      } else if (
        !this.Link.bioLink.pageTitle &&
        this.Link.bioLink.pageDescription
      ) {
        htmlContent += `<div class="container-fluid">
                <div class="row justify-content-center">
                  <div class="col-12 col-sm-8 col-md-8 col-lg-4 d-flex flex-column jutify-content-center align-items-center">
                    <div style="margin-bottom:10px">
                       </div>
                           <div style="
                           font-size: 17px;
                            text-align: center !important;
                            outline: none;
                            padding-bottom: 15px;
                            background-color: transparent;
                            color: ${this.Link.bioLink.fontColor};
                            font-family:  ${this.Link.bioLink.fontFamily};
                           ">
                      ${this.Link.bioLink.pageDescription}
                      </div>
                      </div>
        `;
      }
      else{
        htmlContent += `<div class="container-fluid">
                <div class="row justify-content-center">
                  <div class="col-12 col-sm-8 col-md-8 col-lg-4 d-flex flex-column jutify-content-center align-items-center">
                   
        `;
      }

      this.Link.bioLink.blockElements.forEach((obj) => {
        htmlContent += obj.content + "\n";
      });

      htmlContent += `
      </div>
      </div>
      </div>

      </body>
        </html>`;

      this.Link.htmlContent = htmlContent;
      this.Link.bioLink.content = htmlContent;
      this.createLink();
    },
    previewPage() {
      // Create an HTML string based on the content of each object
      let htmlContent = `<!DOCTYPE html>
                <html lang="en">
                <head>
                  <meta charset="UTF-8">
                  <title>${this.Link.bioLink.tabTitle}</title>
                  <meta name="viewport" content="width=device-width, initial-scale=1">
                <link rel="preconnect" href="https://fonts.googleapis.com">
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Saira+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Space+Grotesk:wght@300..700&display=swap" rel="stylesheet">
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous">
                </head>
                <body style="display: flex;flex-direction: column; justify-content: center; align-items: center; font-family: ${this.Link.bioLink.fontFamily}; background-color: ${this.Link.bioLink.backgroundColor};color: ${this.Link.bioLink.backgroundColor};">


                `;
      htmlContent +=
        "<scr" +
        'ipt async="" src="https://www.tiktok.com/embed.js"></scr' +
        "ipt>";
      if (
        this.isBase64Image(this.Link.bioLink.avatar) &&
        this.Link.bioLink.verifiedIcon
      ) {
        htmlContent += `<div style="position: relative"><img src="${this.Link.bioLink.avatar}" alt="Profile Avatar" style="height: 120px; width: 120px; border-radius: 50%;margin-top: 30px">\n
          <div style="width: 26px; height: 26px; position: absolute;
                  top: 10%;; right: -10px">
              <button
                v-if="verifiedIcon"
                style="
                  height: 25px;
                  width: 25px;
                  border: 0;
                  border-radius: 50%;
                 
                  right: -35%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: #fff;
                  box-shadow: rgba(0, 0, 0, 0.35) 0 3px 5px;
                  margin: 0 10px 0 10px;
                "
              >
                <img width="20" src="https://www.cdnly.org/asset/cblue.png" alt="verified">
              </button>
            </div>          
          </div>`;
      } else if (
        !this.isBase64Image(this.Link.bioLink.avatar) &&
        this.Link.bioLink.verifiedIcon
      ) {
        htmlContent += `<div style="position: relative">\n
          <div style="width: 26px; height: 26px; position: absolute;
                  top: 10%; right: -10px">
              <button
                style="
                  height: 25px;
                  width: 25px;
                  border: 0;
                  top: 10%;
                  right: -35%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: #fff;
                  box-shadow: rgba(0, 0, 0, 0.35) 0 3px 5px;
                  margin: 0 10px 0 10px;
                "
              >
                <img width="20" src="https://www.cdnly.org/asset/cblue.png" alt="verified">
              </button>
            </div>          
          </div>`;
      } else if (
        this.isBase64Image(this.Link.bioLink.avatar) &&
        !this.Link.bioLink.verifiedIcon
      ) {
        htmlContent += `<div style="position: relative">
          <img src="${this.Link.bioLink.avatar}" alt="Profile Avatar" style="height: 120px; width: 120px; border-radius: 50%;margin-top: 30px">\n       
          </div>`;
      }

      if (this.Link.bioLink.pageTitle && this.Link.bioLink.pageDescription) {
        htmlContent += `<div class="container-fluid">
                <div class="row justify-content-center">
                  <div class="col-12 col-sm-8 col-md-8 col-lg-4 d-flex flex-column jutify-content-center align-items-center">
                    <div style="margin-bottom:10px">
                      <div style="
                            font-size: 20px;
                            font-weight: bold;
                            text-align: center !important;
                            padding-top: 18px;
                            padding-bottom: 8px;
                            background-color: transparent !important;
                            color: ${this.Link.bioLink.fontColor};
                            font-family:  ${this.Link.bioLink.fontFamily};
                            ">
                          ${this.Link.bioLink.pageTitle}
                          </div>
                           <div style="
                           font-size: 17px;
                            text-align: center !important;
                            outline: none;
                            padding-bottom: 15px;
                            background-color: transparent;
                            color: ${this.Link.bioLink.fontColor};
                            font-family:  ${this.Link.bioLink.fontFamily};
                           ">
                      ${this.Link.bioLink.pageDescription}
                      </div>
                      </div>
        `;
      } else if (
        this.Link.bioLink.pageTitle &&
        !this.Link.bioLink.pageDescription
      ) {
        htmlContent += `<div class="container-fluid">
                <div class="row justify-content-center">
                  <div class="col-12 col-sm-8 col-md-8 col-lg-4 d-flex flex-column jutify-content-center align-items-center">
                    <div style="margin-bottom:10px">
                      <div style="
                           font-size: 20px;
                            font-weight: bold;
                            text-align: center !important;
                            padding-top: 18px;
                            padding-bottom: 8px;
                            background-color: transparent !important;
                            color: ${this.Link.bioLink.fontColor};
                            font-family:  ${this.Link.bioLink.fontFamily};
                            ">
                          ${this.Link.bioLink.pageTitle}
                          </div>
                      </div>
        `;
      } else if (
        !this.Link.bioLink.pageTitle &&
        this.Link.bioLink.pageDescription
      ) {
        htmlContent += `<div class="container-fluid">
                <div class="row justify-content-center">
                  <div class="col-12 col-sm-8 col-md-8 col-lg-4 d-flex flex-column jutify-content-center align-items-center">
                    <div style="margin-bottom:10px">
                       </div>
                           <div style="
                           font-size: 17px;
                            text-align: center !important;
                            outline: none;
                            padding-bottom: 15px;
                            background-color: transparent;
                            color: ${this.Link.bioLink.fontColor};
                            font-family:  ${this.Link.bioLink.fontFamily};
                            ">
                      ${this.Link.bioLink.pageDescription}
                      </div>
                      </div>
        `;
      }
      else{
        htmlContent += `<div class="container-fluid">
                <div class="row justify-content-center">
                  <div class="col-12 col-sm-8 col-md-8 col-lg-4 d-flex flex-column jutify-content-center align-items-center">
                   
        `;
      }
      this.Link.bioLink.blockElements.forEach((obj) => {
        htmlContent += obj.content + "\n";
      });

      htmlContent += `
      </div>
      </div>
      </div>

      </body>
        </html>`;

      this.Link.bioLink.content = htmlContent;
      // Create a Blob from the HTML content
      const blob = new Blob([htmlContent], { type: "text/html" });

      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);

      // Open the URL in a new tab
      window.open(url, "_blank");
    },
    handleImage(e) {
      const selectedImage = e.target.files[0];
      this.createBase64Image(selectedImage);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        // Assign the base64 image to this.image
        this.socialButtonItem.logo = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    addMySocial() {
      console.log(this.socialButtonItem);
      let anchorHtml = "";
      if (this.socialButtonItem.logo) {
        anchorHtml = `<a href="${this.socialButtonItem.link}" target="_blank">
            <button style="border: 0; outline: none; padding: 8px 22px; font-size: 18px; border-radius: 5px; cursor: pointer; color: #fff;background: ${this.socialButtonItem.color}">
                <img width="25px" style="margin-right: 5px" height="px" src="${this.socialButtonItem.logo}" alt="Logo" />
                ${this.socialButtonItem.text}
            </button>
        </a>`;
      } else {
        anchorHtml = `<a href="${this.socialButtonItem.link}" target="_blank">
            <button style="border: 0; outline: none; padding: 8px 20px; font-size: 18px; border-radius: 5px; cursor: pointer; color: #fff;background: ${this.socialButtonItem.color}">
                ${this.socialButtonItem.text}
            </button>
        </a>`;
      }
      this.socialButtonItem.content = anchorHtml;
      this.Link.bioLink.socialButtons.push(this.socialButtonItem);
      this.socialLinksDialog = false;
      this.socialButtonItem = {
        color: "#0d6efd",
        text: "",
        link: "",
        logo: "",
      };
    },
    async generateByteArray() {
      try {
        let response = await qrCodeService.checkSlug(
          this.Link.linkGuid,
          this.Link.linkId
        );
        this.Link.linkGuid = response.data;
        this.updateQrcode = new Date();

        // Wait for the QR code generation to complete
        await this.$nextTick();

        // Fetch the image source after waiting for the asynchronous operations
        await new Promise((resolve) => setTimeout(resolve, 0)); // This micro-task ensures the next tick
        const imgSrc = document.querySelector(".img-qr").getAttribute("src");

        let model = {
          linkGuid : this.Link.linkGuid,
          qrCode : imgSrc
        }
        this.Link.imageLink = await this.uploadImage(model);

        //this.Link.qrCode = imgSrc;
        // Now call the CreateURL method
        this.logHtmlContent();
      } catch (error) {
        console.error("Error generating Base64 string:", error);
      }
    },
    async uploadImage(model) {
      try {
        let response = await qrCodeService.uploadImage(model);
        return response?.data ?? "";
      } catch (e) {
        ShowMessage("error", "QrCode creation failed")
      }
    },
    deleteBlockElement(itemToDelete) {
      // Find the index of the item in the array
      const index = this.Link.bioLink.blockElements.indexOf(itemToDelete);

      // If the item is found, remove it from the array
      if (index !== -1) {
        this.Link.bioLink.blockElements.splice(index, 1);
      }
    },

    changeFont() {
      let page = document.getElementById("page");
      page.style.fontFamily = this.Link.bioLink.fontFamily;
    },
    addHeading() {
      this.Link.bioLink.pageTitle == this.pageTitle;
      console.log(this.Link.bioLink.pageTitle, this.pageTitle);
      /*   let h2 = document.createElement("h2");
         h2.style.color = this.textColor;
         h2.textContent = this.headingFieldText;
         let page = document.getElementById("page");
         page.appendChild(h2);*/
    },
    addParagraph() {
      let embedCode = `<span style="color: ${this.textColor}">${this.paragraphFieldText}</span>`;
      let blockElement = {
        content: embedCode,
      };
      this.Link.bioLink.blockElements.push(blockElement);
      /* let span = document.createElement("span");
       span.style.color = this.textColor;
       span.textContent = this.paragraphFieldText;
       let page = document.getElementById("page");
       page.appendChild(span);*/
    },
    changeBgColor() {
      let pageDiv = document.getElementById("page");
      pageDiv.style.backgroundColor = this.Link.bioLink.backgroundColor;
    },
    changeTxtColor() {
      let pageDiv = document.getElementById("page");
      pageDiv.style.color = this.Link.bioLink.fontColor;
    },
    async createLink() {
      try {
        if (this.Link.title === "") {
          ShowMessage("error", "Please enter the title");
          return;
        }
        console.log(this.Link);
        this.loading = true;
        let response = await linkService.addOrUpdate(this.Link);
        if (response.data.isSuccessful) {
          ShowMessage("success", "Biolink created successfully");
          this.$router.push("/biolinks");
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        ShowMessage("error", "Something went wrong!");
        console.log(e);
      }
    },
    ensureHttps(link) {
      // Check if the link starts with "http://" or "https://"
      if (!link.startsWith("http://") && !link.startsWith("https://")) {
        // If not, prepend "https://"
        link = "https://" + link;
      }
      return link;
    },

    applyFont(newDoc) {
      if (this.fontFamily === "Poppins") {
        let fontLink3 = document.createElement("link");
        fontLink3.rel = "stylesheet";
        fontLink3.href =
          "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
        newDoc.head.appendChild(fontLink3);
      }
      if (this.fontFamily === "Poppins") {
        let fontLink4 = document.createElement("link");
        fontLink4.rel = "stylesheet";
        fontLink4.href =
          "https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap";
        newDoc.head.appendChild(fontLink4);
      }

      if (this.fontFamily === "Noto Sans") {
        let fontLink4 = document.createElement("link");
        fontLink4.rel = "stylesheet";
        fontLink4.href =
          "https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Quicksand:wght@300..700&display=swap";
        newDoc.head.appendChild(fontLink4);
      }
      if (this.fontFamily === "Saira Condensed") {
        let fontLink4 = document.createElement("link");
        fontLink4.rel = "stylesheet";
        fontLink4.href =
          "https://fonts.googleapis.com/css2?family=Saira+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap";
        newDoc.head.appendChild(fontLink4);
      }
      if (this.fontFamily === "Space Grotesk") {
        let fontLink4 = document.createElement("link");
        fontLink4.rel = "stylesheet";
        fontLink4.href =
          "https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap";
        newDoc.head.appendChild(fontLink4);
      }
      if (this.fontFamily === "Josefin Sans") {
        let fontLink4 = document.createElement("link");
        fontLink4.rel = "stylesheet";
        fontLink4.href =
          "https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap";
        newDoc.head.appendChild(fontLink4);
      }
      if (this.fontFamily === "Montserrat") {
        let fontLink4 = document.createElement("link");
        fontLink4.rel = "stylesheet";
        fontLink4.href =
          "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap";
        newDoc.head.appendChild(fontLink4);
      }
    },
    addInstagramProfile() {
      let index = 0;
      const existingElement = this.Link.bioLink.blockElements.find(
        (element) => element.id === this.blockElement.id
      );
      if (existingElement !== undefined) {
        index = this.Link.bioLink.blockElements.findIndex(
          (element) => element.id === this.blockElement.id
        );
      }
      if (!this.blockElement.link) {
        ShowMessage("error", "Please enter Instagram username");
        return;
      }
      this.blockElement.elementType = 5;
      // Create the embed code with iframe element
      let content = `<div class="instagram-container" style="margin-top: 10px;">
            <iframe class="instagram-media instagram-media-rendered"
                    src="https://www.instagram.com/${this.blockElement.link}/embed/"
                    style="background-color: #fff; border: 0;  width: 450px; border-radius: 10px; height: 450px;">
            </iframe>
            </div>`;
      if (this.blockElement.id > 0) {
        this.Link.bioLink.blockElements[index].content = content;
        // You can update other properties here if needed
      } else {
        // Add new element
        this.blockElement.content = content;
        this.blockElement.id =
          this.Link.bioLink.blockElements.reduce(
            (max, element) => (element.id > max ? element.id : max),
            0
          ) + 1;
        this.Link.bioLink.blockElements.push(this.blockElement);
      }
      // Clear the input field
      this.resetBlockElement();
    },

    addTikTokEmbed() {
      let index = 0;
      let existingElement = this.Link.bioLink.blockElements.find(
        (element) => element.id === this.blockElement.id
      );
      if (existingElement !== undefined) {
        index = this.Link.bioLink.blockElements.findIndex(
          (element) => element.id === this.blockElement.id
        );
      }
      if (!this.blockElement.link) {
        ShowMessage("error", "Please enter TikTok username");
        return;
      }
      this.blockElement.elementType = 4;

      // Original TikTok embed code
      let tikTokEmbedCode =
        '<blockquote  style="background-color: #fff; border: 0;margin-top: 10px;  width: 450px; border-radius: 10px; height: 345px;"  class="tiktok-embed" cite="https://www.tiktok.com/@rooshtech" data-unique-id="rooshtech" data-embed-type="creator" id="v72455443443648600"> <section> <a target="_blank" href="https://www.tiktok.com/@rooshtech?refer=creator_embed">@rooshtech</a> </section> <iframe name="__tt_embed__v72455443443648600" sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-top-navigation allow-same-origin" src="https://www.tiktok.com/embed/@rooshtech?lang=en-GB&amp;referrer=https%3A%2F%2Flinke.to%2Ftestygfc" __idm_id__="90113"></iframe></blockquote>';

      // Replace the TikTok username in the embed code with your variable
      const content = tikTokEmbedCode.replace(
        /@rooshtech/g,
        "@" + this.blockElement.link
      );
      if (this.blockElement.id > 0) {
        this.Link.bioLink.blockElements[index].content = content;
        // You can update other properties here if needed
      } else {
        // Add new element
        this.blockElement.content = content;
        this.blockElement.id =
          this.Link.bioLink.blockElements.reduce(
            (max, element) => (element.id > max ? element.id : max),
            0
          ) + 1;
        this.Link.bioLink.blockElements.push(this.blockElement);
      }
      // Clear the input field
      this.resetBlockElement();
    },
    isBase64Image(imageUrl) {
      if (imageUrl === "" || imageUrl === null || imageUrl === undefined) {
        return;
      }
      // Check if the image URL starts with the specified base64 prefix
      return (
        imageUrl.startsWith("data:image/jpeg;base64,") ||
        imageUrl.startsWith("data:image/png;base64,")
      );
    },
    handleFileImport(type) {
      switch (type) {
        case "avatar":
          this.$refs.uploader1.click();
          break;
        case "button":
          this.$refs.uploader2.click();
          break;
      }
    },
    onFileChanged(type) {
      this.fileInputKey = new Date();
      let fileInput = undefined;
      switch (type) {
        case "avatar":
          fileInput = document.getElementById("avatar");
          break;
        case "button":
          fileInput = document.getElementById("button");
          break;
      }

      // Check if a file was selected
      if (fileInput.files && fileInput.files.length) {
        const selectedFile = fileInput.files[0];

        // Convert the selected image file to Base64
        this.convertFileToBase64(selectedFile)
          .then((base64Data) => {
            // Assign the Base64 data to this.qrCode.image
            switch (type) {
              case "avatar":
                this.Link.bioLink.avatar =
                  "data:image/png;base64," + base64Data;
                break;
              case "button":
                this.blockElement.logo = "data:image/png;base64," + base64Data;
                break;
            }
          })
          .catch((error) => {
            console.error("Error converting image to Base64:", error);
          });
      }
    },
    convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result.split(",")[1]); // Extract Base64 data
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    },
    createImage(file) {
      this.loader = true;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.Link.bioLink.avatar = e.target.result.split(",")[1];
      };
      reader.readAsDataURL(file.target.files[0]);
      this.isSelecting = false;
      this.loader = false;
    },

    applyColor(colorCode) {
      let pageDiv = document.getElementById("page");
      pageDiv.style.backgroundColor = colorCode;
      this.Link.bioLink.backgroundColor = colorCode;
    },
    checkPixel() {
      this.Link.pixelCodes = [];
      this.localPixelCodes.forEach((element) => {
        let object = {
          pixelId: element.id,
        };
        this.Link.pixelCodes.push(object);
      });
    },
    async getDomains() {
      try {
        this.loader = true;
        let response = await domainService.all(this.filter);
        this.domains = response.data.data;
        this.loader = false;
      } catch (e) {
        this.loader = false;
        console.log(e);
      }
    },
    async getPixels() {
      try {
        this.loader = true;
        let response = await pixelCodeService.getAll(this.filter);
        this.pixelCodes = response.data.data;
        this.loader = false;
      } catch (e) {
        console.log(e);
      }
    },
    async getBioLink() {
      try {
        this.loading = true;
        let res = await linkService.getLink(store.state.pageId);
        this.Link = res.data.data;
        /*  setTimeout(() => {
            this.addEvents();
          }, 0);*/
        if (this.Link.pixelCodes != null && this.Link.pixelCodes.length > 0) {
          this.Link.pixelCodes.forEach((element) => {
            this.localPixelCodes.push(element);
          });
        }
        this.changeFont();
        this.changeTxtColor();
        this.changeBgColor();
        (this.updateQrcode = new Date()), (this.loading = false);
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    removeAvatar() {
      this.Link.bioLink.avatar = "";
    },
  },
  created() {
    if (store.state.pageId > 0) {
      this.getBioLink();
    }
    this.getPixels();
    this.getDomains();
  },
};
</script>

<style scoped>
.edit-btn1 {
  position: absolute;
  z-index: 2;
  left: -15px;
}

.edit-btn2 {
  position: absolute;
  right: -15px;
}

.content-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 450px;
  background-color: #fff;
  min-height: 62.4px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  box-sizing: border-box;
  border-radius: 10px;
  max-width: 90%;
}
.content-container-text {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 450px;
  background-color: transparent;
  min-height: 62.4px;
  box-sizing: border-box;
  border-radius: 10px;
  max-width: 90%;
}

.content-main {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}
::-webkit-scrollbar {
  display: none !important;
}
input[type="color"]::-webkit-color-swatch {
  visibility: hidden;
}
.biolink_loader {
  position: absolute;
  left: calc(50% + 140px);
}
/* .avatar:after {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    right: -40px;
    top: 30px;
    background-image: url(https://www.cdnly.org/asset/cblue.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    z-index: 1;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
} */
.page-input {
  border: 0 !important;
  background: transparent;
  outline: 0 !important;
  text-align: center;
}
.pg-head {
  font-size: 20px;
  font-weight: bold;
  height: 40px;
  margin-top: 12px;
}
.pg-desc {
  font-size: 17px;
  text-align: center;
  outline: none;
  padding-top: 3px;
  padding-bottom: 10px;
  margin-top: 3px;
  background-color: transparent;
}
</style>
