<template>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-12 col-md-8">
        <h3 class="text-uppercase">Qr Codes</h3>
      </div>
      <div class="col-12 col-md-4 d-flex">
        <el-input v-model="filter.filterText" @keyup="GetAll()" placeholder="search here..." clearable class="me-1">
          <template #append>
            <el-button type="primary">
              <i class="bi bi-search"></i>
            </el-button>
          </template>
        </el-input>
        <router-link to="addcode">
          <el-button @click="$store.state.pageId = 0" type="primary">Add Qr Code</el-button>
        </router-link>
      </div>
    </div>
    <el-divider class="mt-1"></el-divider>
    <el-table :data="QrCodes" :fit="true" max-height="80vh" class="cstm-table" header-cell-class-name="tbl-header"
              size="small" stripe>
      <template #empty>
        <el-empty :image-size="100" class="py-1">
          <template #description>
            <span class="my-0">No Data Found</span>
          </template>
        </el-empty>
      </template>
      <template #append>
        <div class="d-flex justify-content-center py-2 px-1">
          <el-pagination v-if="QrCodes.length > 0" v-model:current-page="pagination.page"
                         v-model:page-size="pagination.pageSize" :page-sizes="[25, 50, 100]" :total="pagination.fetched"
                         background
                         layout="prev, pager, next, ,sizes" small/>
        </div>
      </template>
      <el-table-column label="Title" prop="title">
        <template #default="scope">
          <div class="d-flex">
            <img  :src="scope.row.imageLink" width="150px" height="150px" alt="QrCode"/>
            <div class="ms-2 d-flex flex-column">
              <span class="qrcode-title">{{ scope.row.title }}</span>
              <el-switch @change="UpdateCode(scope.row)" class="" active-color="green"
                         v-model="scope.row.isActive"></el-switch>
              <div class="d-flex">
                <span class="text-primary mfw-bold">Code URL:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <a class="code-link-a" :href="scope.row.redirectionLink">{{ scope.row.redirectionLink }}</a>
                <el-button @click="CopyLink(scope.row.redirectionLink)" text bg size="small" circle class="ms-2">
                  <i class="bi bi-link-45deg fw-bold"></i>
                </el-button>
              </div>
              <div class="d-flex">
                <span class="text-primary mfw-bold">Tracked URL:&nbsp;</span>
                <a class="code-link-a" :href="scope.row.trackedUrl">{{ scope.row.trackedUrl }}</a>
                <el-button @click="CopyLink(scope.row.trackedUrl)" text bg size="small" circle class="ms-2">
                  <i class="bi bi-link-45deg fw-bold"></i>
                </el-button>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Visits" prop="visits" width="80">
      </el-table-column>

      <el-table-column align="center" fixed="right" label="Action" width="100px">
        <template #default="scope">
          <el-space>
            <el-tooltip content="Download">
              <el-icon @click="showCode(scope.row)">
                <i class="mgc_download_line text-primary action-icon fs-6"></i>
              </el-icon>
            </el-tooltip>
            <el-tooltip content="Edit">
              <el-icon @click="editCode(scope.row)">
                <i class="mgc_edit_line text-secondary action-icon fs-6"></i>
              </el-icon>
            </el-tooltip>
            <el-tooltip content="Duplicate">
              <el-icon @click="duplicate(scope.row)">
                <i class="mgc_copy_3_line text-black action-icon fs-6"></i>
              </el-icon>
            </el-tooltip>
            <el-tooltip content="Delete">
              <el-icon color="red" @click="deleteCode(scope.row)">
                <i class="mgc_delete_2_line action-icon fs-6"></i>
              </el-icon>
            </el-tooltip>
          </el-space>
        </template>
      </el-table-column>
    </el-table>


    <el-dialog v-model="deleteDialog" title="Delete Qr Code" width="450">
      <template #default>
        <span>Are you sure? You want to delete <span class="mfw-bold text-danger">{{ qrCode.title }}</span> qr
          code.</span>
      </template>
      <template #footer>
        <div class="container-fluid px-0 d-flex justify-content-end">
          <el-button text bg @click="deleteDialog = false">Cancel</el-button>
          <el-button type="danger" @click="deleteDialog = false, RemoveCode()">Delete</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="duplicateDialog" title="Delete Qr Code" width="450">
      <template #default>
        <span>Are you sure? You want to duplicate <span class="mfw-bold text-primary">{{ qrCode.title }}</span> qr
          code.</span>
      </template>
      <template #footer>
        <div class="container-fluid px-0 d-flex justify-content-end">
          <el-button text bg @click="duplicateDialog = false">Cancel</el-button>
          <el-button type="primary" @click="duplicateDialog = false, duplicateCode()">Duplicate</el-button>
        </div>
      </template>
    </el-dialog>


<!--    <el-dialog v-model="showQrCode" width="300" title="Download Qr Code" draggable>-->
<!--      <template #default>-->
<!--        <div class="text-center">-->
<!--          <img v-if="isBase64Image(currentCampaign.image)" :src="'data:image/jpeg;base64,' + currentCampaign.image"-->
<!--               width="100px" height="100px" alt="Qr Code"/>-->

<!--          <img v-else :src="currentCampaign.image" width="100px" height="100px" alt="Qr Code"/>-->
<!--        </div>-->
<!--      </template>-->
<!--      <template #footer>-->
<!--        <div class="container-fluid px-0 d-flex justify-content-end">-->
<!--          <el-button text bg @click="showQrCode = false">Cancel</el-button>-->
<!--          <el-button type="primary" @click="showQrCode = false, downloadCode()">Download</el-button>-->
<!--        </div>-->
<!--      </template>-->
<!--    </el-dialog>-->
    <el-dialog v-model="showQrCode" width="300" title="Download Qr Code" draggable>
      <template #default>
        <el-select v-model="designId" @change="updateQrcode = new Date(), changeDesign()" class="mb-1" placeholder="Select QrCode Design">
          <el-option v-for="design in designs" :key="design.id" :label="design.title"
                     :value="design.id"></el-option>
        </el-select>
        <div class="text-center">
          <div v-if="design.id < 1">
            <img  :src="qrCode.imageLink" width="170px" height="170px" alt="QrCode"/>
<!--            <img v-else :src="'data:image/jpeg;base64,' + qrCode.qrCode" width="170px" height="170px" alt="QrCode"/>-->
          </div>
          <QRCodeVue3 v-else ref="qrcode" :width="170" :height="170" :value="qrCode.redirectionLink" :key="updateQrcode"
                      :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
                      :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }"

                      :dotsOptions="design.dotsOptions"
                      v-bind:image="design.image" :backgroundOptions="{ color: '#ffffff' }"
                      :cornersSquareOptions="design.corner.cornersSquareOptions"
                      :cornersDotOptions="design.corner.cornersDotOptions" fileExt="png"

                      myclass="my-qur"
                      imgclass="img-qr" downloadButton="btn btn-primary"
                      :downloadOptions="{ name: 'vqr', extension: 'webp' }"/>
        </div>
      </template>
      <template #footer>
        <div class="container-fluid px-0 d-flex justify-content-end">
          <el-button text bg @click="showQrCode = false">Cancel</el-button>
          <el-button type="primary" @click="showQrCode = false, downloadCode()">Download</el-button>
        </div>
      </template>
    </el-dialog>

    <div v-if="loading" class="spinner-border d-loader"></div>
<!--    <ShowQrCode :redirection-link="qrCode.redirectionLink"></ShowQrCode>-->
  </div>
</template>
<script>
import qrCodeService from "@/Services/linkService";
import {ShowMessage} from "@/Utility/Utility";
//import ShowQrCode from "@/components/Utility/ShowQrCode.vue";
import designService from "@/Services/designService";
import QRCodeVue3 from "qrcode-vue3";
export default {
  name: "QrCodes",
  components: {QRCodeVue3},
  data() {
    return {
      loading: false,
      dialog: false,
      showQrCode: false,
      QrCodes: [],
      qrCode: {
        "title": ""
      },
      deleteDialog: false,
      duplicateDialog: false,
      link: "your link here",
      singleSelect: false,
      user: localStorage.getItem("user"),
      selected: [],
      selectedqrcodeIds: [],
      isActive: true,
      currentCode: [],
      updateQrcode: new Date(),
      designs:[],
      designId:"",
      design: {
        id: 0,
        title: "",
        image: "",
        userId: sessionStorage.getItem("userId"),
        cornerGradient: false,
        primaryGradient: false,
        corner: {
          cornersSquareOptions: {
            type: "extra-rounded",
            gradient: {
              type: 'linear',
              rotation: 0,
              colorStops: [
                {offset: 0, color: '#000'},
                {offset: 1, color: '#000'},
              ],
            },
          },
          cornersDotOptions: {
            type: "extra-rounded",
            gradient: {
              type: 'linear',
              rotation: 0,
              colorStops: [
                {offset: 0, color: '#000'},
                {offset: 1, color: '#000'},
              ],
            },
          },

        },
        dotsOptions: {
          type: 'dot',
          color: '#26249a',
          gradient: {
            type: 'linear',
            rotation: 0,
            colorStops: [
              {offset: 0, color: '#000'},
              {offset: 1, color: '#000'},
            ],
          },
        },
      },

      page: 1,
      options: [
        {text: "10", value: 10},
        {text: "25", value: 25},
        {text: "50", value: 50},
        {text: "100", value: 100},
      ],
      pagination: null,
      filter: {
        pageSize: 25,
        page: 1,
        filterText: "",
        isActive: true,
        userId: sessionStorage.getItem("userId"),
        linkType: 10,
      },
    }
  },
  methods: {
    changeDesign(){
      const selectedDesign = this.designs.find(design => Number(design.id) === Number(this.designId));
      this.design = selectedDesign;
      if (selectedDesign) {
        this.design = selectedDesign;
      } else {
        console.log(`Design with id ${this.designId} not found.`);
      }
    },
    isBase64Image(imageUrl) {
      if (imageUrl === '' || imageUrl === null) {
        return;
      }
      // Check if the image URL starts with the specified base64 prefix
      return imageUrl.startsWith("data:image/jpeg;base64,") || imageUrl.startsWith("data:image/png;base64,");
    },
    editCode(code) {
      this.$store.state.pageId = code.linkId;
      this.$router.push('/addcode');
    },
    duplicate(code) {
      this.qrCode = {...code};
      this.duplicateDialog = true;
    },

    CopyLink(link) {
      navigator.clipboard.writeText(link);
    },
    deleteCode(code) {
      this.qrCode = {...code};
      this.deleteDialog = true;
    },
    async UpdateCode(qrCode) {
      try {
        this.loading = true;
        let response = await qrCodeService.addOrUpdate(qrCode)
        this.loader = false;
        if (response.data.isSuccessful) {
          ShowMessage("success", "Qr Code updated");
        } else {
          ShowMessage("error", "Qr Code updated failed");
        }
        this.GetAll();
      } catch (error) {
        this.loader = false;
        console.log(error);
      }
    },
    async GetAll() {
      try {
        this.loading = true;
        let response = await qrCodeService.getAll(this.filter);
        this.QrCodes = response.data.data ?? [];
        this.pagination = response.data.pagination;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e)
      }
    },
    async duplicateCode() {
      try {
        this.loading = true;
        let response = await qrCodeService.duplicate(this.qrCode);
        if (response.status === 200) {
          ShowMessage("success", "Qr Code duplicated successfully!");
          this.GetAll();
        }
      } catch (e) {
        this.loading = false;
        ShowMessage("error", "Something went wrong!.")
        console.log(e)
      }
    },
    async RemoveCode() {
      try {
        this.loading = true;
        let response = await qrCodeService.remove(this.qrCode.linkId);
        if (response.status === 200) {
          ShowMessage("success", "QrCode deleted successfully!");
          this.GetAll();
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
        ShowMessage("error", "Something went wrong!.")
      }
    },
    showCode(qrCode) {
      this.designId = "";
      this.resetDesign();
      this.qrCode = JSON.parse(JSON.stringify(qrCode));
      this.updateQrcode = new Date();
      this.showQrCode = true;
    },
    resetDesign() {
      this.design = {
        id: 0,
        title: "",
        image: "",
        userId: sessionStorage.getItem("userId"),
        cornerGradient: false,
        primaryGradient: false,
        corner: {
          cornersSquareOptions: {
            type: "extra-rounded",
            gradient: {
              type: 'linear',
              rotation: 0,
              colorStops: [
                {offset: 0, color: '#000'},
                {offset: 1, color: '#000'},
              ],
            },
          },
          cornersDotOptions: {
            type: "extra-rounded",
            gradient: {
              type: 'linear',
              rotation: 0,
              colorStops: [
                {offset: 0, color: '#000'},
                {offset: 1, color: '#000'},
              ],
            },
          },

        },
        dotsOptions: {
          type: 'dot',
          color: '#26249a',
          gradient: {
            type: 'linear',
            rotation: 0,
            colorStops: [
              {offset: 0, color: '#000'},
              {offset: 1, color: '#000'},
            ],
          },
        },
      };
    },
    downloadCode() {
      if (this.design.id < 1) {
        let a = document.createElement("a"); //Create <a>
        if (!this.isBase64Image(this.qrCode.qrCode)) {
          a.href = "data:image/png;base64," + this.qrCode.qrCode;
        } else {
          a.href = this.qrCode.qrCode
        }//Image Base64 Goes here
        a.download = this.qrCode.title + " " + "Qr Code.png"; //File name Here
        a.click(); //Downloaded file
      } else {
        this.generateByteArray()
      }
    },
    async generateByteArray() {
      try {

        await this.$nextTick();
        // Fetch the image source after waiting for the asynchronous operations
        await new Promise(resolve => setTimeout(resolve, 0)); // This micro-task ensures the next tick
        const imgSrc = document.querySelector('.img-qr').getAttribute('src');
        this.downloadImage(imgSrc)
      } catch (error) {
        console.error('Error generating Base64 string:', error);
      }
    },
    downloadImage(imgSrc) {
      let a = document.createElement("a"); //Create <a>
      if (!this.isBase64Image(imgSrc)) {
        a.href = "data:image/png;base64," + imgSrc;
      } else {
        a.href = imgSrc
      }//Image Base64 Goes here
      a.download = this.qrCode.title + " " + "Qr Code.png";  //File name Here
      a.click(); //Downloaded file
    },
    async getAllDesigns() {
      try {
        this.loading = true;
        let response = await designService.all(this.filter);
        this.designs = response?.data?.data;

        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e)
      }
    },
  },
  created() {

    this.GetAll();
    this.getAllDesigns();
  }
}
</script>

<style scoped></style>