<template>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-12 col-md-8">
        <h3 class="text-uppercase">Bio Links</h3>
      </div>
      <div class="col-12 col-md-4 d-flex">
        <el-input v-model="filter.filterText" placeholder="search here..." @keyup="getAll()" clearable class="me-1">
          <template #append>
            <el-button type="primary">
              <i class="bi bi-search"></i>
            </el-button>
          </template>
        </el-input>
        <router-link to="addbiolink">
          <el-button @click="$store.state.pageId = 0" type="primary">Add BioLink</el-button>
        </router-link>
      </div>
    </div>
    <el-divider class="mt-1"></el-divider>
    <el-table :data="bioLinks" :fit="true" max-height="80vh"
              class="cstm-table" header-cell-class-name="tbl-header" size="small" stripe
    >
      <template #append>
        <div class="d-flex justify-content-center py-2 px-1">
          <el-pagination
              v-if="bioLinks.length > 0"
              v-model:current-page="pagination.page"
              v-model:page-size="pagination.pageSize"
              :page-sizes="[25, 50, 100]"
              :total="pagination.fetched"
              background
              layout="prev, pager, next, ,sizes"
              small
          />
        </div>
      </template>
      <el-table-column label="#" type="index" width="55" align="center"></el-table-column>
      <el-table-column label="Title" prop="title"></el-table-column>
      <el-table-column label="Link" prop="redirectionLink"></el-table-column>
      <el-table-column label="Visits" prop="visits" width="50">
      </el-table-column>
      <el-table-column align="center" label="Action" width="120px">
        <template #default="scope">
          <el-space>
            <el-tooltip content="Copy Link">
              <el-icon @click="takeData(scope.row,'copy')">
                <i class="mgc_link_2_line text-black action-icon fs-6"></i>
              </el-icon>
            </el-tooltip>
            <el-tooltip content="Edit">
              <el-icon @click="takeData(scope.row,'edit')">
                <i class="mgc_edit_line text-secondary action-icon fs-6"></i>
              </el-icon>
            </el-tooltip>
            <el-tooltip content="Download">
              <el-icon @click="takeData(scope.row,'qrcode')">
                <i class="mgc_qrcode_2_line text-primary action-icon fs-6"></i>
              </el-icon>
            </el-tooltip>
            <el-tooltip content="Delete">
              <el-icon color="red" @click="takeData(scope.row,'del')">
                <i class="mgc_delete_2_line action-icon fs-6"></i>
              </el-icon>
            </el-tooltip>
          </el-space>
        </template>
      </el-table-column>
    </el-table>

    <!--    Dialogs-->

    <el-dialog v-model="deleteDialog" title="Delete Biolink" width="450">
      <template #default>
        <span>Are you sure? You want to delete <span
            class="mfw-bold text-danger">{{ bioLink.title }}</span> Biolink.</span>
      </template>
      <template #footer>
        <div class="container-fluid px-0 d-flex justify-content-end">
          <el-button text bg @click="deleteDialog = false">Cancel</el-button>
          <el-button type="danger" @click="deleteDialog = false, removeLink()">Delete</el-button>
        </div>
      </template>
    </el-dialog>
        <el-dialog v-model="showQrCode" width="300" title="Download Qr Code" draggable>
          <template #default>
            <div class="text-center">
              <img   :src="bioLink.imageLink"
             width="100px"
             height="100px" alt="Qr Code"/>
     
<!--       <img   v-else :src="bioLink.image"-->
<!--             width="100px"-->
<!--             height="100px" alt="Qr Code"/>-->
            </div>
          </template>
          <template #footer>
            <div class="container-fluid px-0 d-flex justify-content-end">
              <el-button text bg @click="showQrCode = false">Cancel</el-button>
              <el-button type="primary" @click="showQrCode = false, downloadCode()">Download</el-button>
            </div>
          </template>
        </el-dialog>


    <div v-if="loading" class="spinner-border d-loader"></div>
  </div>
</template>
<script>
import qrCodeService from "@/Services/linkService";
import linkService from "@/Services/linkService";
import {ShowMessage} from "@/Utility/Utility";

export default {
  name: "BioLink",
  data() {
    return {
      loading: false,
      deleteDialog: false,
      showQrCode: false,
      bioLinks: [],
      bioLink: {},
      pagination: {},
      filter: {
        pageSize: 10,
        page: 1,
        filterText: "",
        isActive: true,
        userId: sessionStorage.getItem("userId"),
        linkType: 40,
      },
    }
  },
  methods: {
    isBase64Image(imageUrl) {
      // Check if the image URL starts with the specified base64 prefix
      return imageUrl.startsWith("data:image/jpeg;base64,");
    },
    takeData(link, type) {
      this.bioLink = {...link};
      if (type === 'del') {
        this.deleteDialog = true;
      }
      if (type === 'copy') {
        navigator.clipboard.writeText(this.bioLink.redirectionLink);
        ShowMessage("success", "Link copied")
      }
      if (type === 'edit'){
        this.$store.state.pageId = this.bioLink.linkId;
        this.$router.push('addbiolink')
      }
      if (type === 'qrcode') {
       this.showQrCode = true;
      }
    },
    downloadCode() {
      const row = this.bioLink
      let a = document.createElement("a"); //Create <a>
      a.href = "data:image/png;base64," + row.image; //Image Base64 Goes here
      a.download = "Image.png"; //File name Here
      a.click(); //Downloaded file
    },
    async removeLink() {
      try {
        this.loading = true;
        let res = await linkService.remove(this.bioLink.linkId);
        if (res.data.isSuccessful) {
          ShowMessage("success", "Biolink deleted successfully!");
        }
        this.getAll();
        this.loading = false
      } catch {
        ShowMessage("error", "something went wrong!");
      }
    },
    async getAll() {
      try {
        this.loading = true;
        let response = await qrCodeService.getAll(this.filter);
        this.bioLinks = response.data.data;
        this.pagination = response.data.pagination;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    }
  },
  created() {
    this.getAll();
  }
}
</script>

<style scoped>

</style>