import {createRouter, createWebHistory} from 'vue-router'
import LoginPage from "@/components/Authentication/LoginPage.vue";
import Dashboard from "@/components/Dashboard/Dashboard.vue";
import QrCodes from "@/components/QR Code/QrCodes.vue";
import AddCode from "@/components/QR Code/AddCode.vue";
import MagicUrls from "@/components/Magic Url/MagicUrls.vue";
import AddMagicUrl from "@/components/Magic Url/AddMagicUrl.vue"
import LinkRotators from "@/components/LinkRotator/LinkRotators.vue"
import AddLink from "@/components/LinkRotator/AddLink.vue"
import Domains from "@/components/Domains/Domains.vue";
import AddDomain from "@/components/Domains/AddDomain.vue";
import DomainInstructions from "@/components/Domains/DomainInstructions.vue";
import PixelsList from "@/components/Pixels/PixelsList.vue"
import AddPixel from "@/components/Pixels/AddPixel.vue"
import Users from "@/components/Users/Users.vue"
import {isAuthenticated, isAdmin} from "@/components/Authentication/Authenticate";
import {ShowMessage} from "@/Utility/Utility";
import ForBidden from "@/components/Pages/ForBidden.vue";
import PageNotFound from "@/components/Pages/PageNotFound.vue";
import ForgotPassword from "@/components/Authentication/ForgotPassword.vue";
import SignUp from "@/components/Authentication/SignUp.vue";
import BioLink from "@/components/BioLink/BioLink.vue";
import AddBioLink from "../components/BioLink/AddBioLink.vue";
import QrDesigns from "@/components/Designs/Designs.vue";
const routes = [
    {
        path: '/',
        name: 'login',
        component: LoginPage
    },
    {
        path: '/forgot_password',
        name: 'ForgotPassword',
        component: ForgotPassword
    },
    {
        path: '/signup',
        name: 'SignUp',
        component: SignUp
    },
    {
        path: '/403',
        name: 'forbidden',
        component: ForBidden
    },
    {
        path: '/home',
        name: 'home',
        component: Dashboard,
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: '/qrcodes',
        name: 'qrcodes',
        component: QrCodes,
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: '/designs',
        name: 'designs',
        component: QrDesigns,
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: '/addcode',
        name: 'addcode',
        component: AddCode,
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: '/magicurls',
        name: 'magicurls',
        component: MagicUrls,
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: '/addmagicurl',
        name: 'addmagicurl',
        component: AddMagicUrl,
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: '/links',
        name: 'LinkRotators',
        component: LinkRotators,
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: '/addlink',
        name: 'AddLink',
        component: AddLink,
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
     {
        path: '/biolinks',
        name: 'BioLink',
        component: BioLink,
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: '/addbiolink',
        name: 'AddBioLink',
        component: AddBioLink,
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: '/domains',
        name: 'Domains',
        component: Domains,
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: '/add-domain',
        name: 'AddDomain',
        component: AddDomain,
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: '/instructions',
        name: 'DomainInstructions',
        component: DomainInstructions,
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: '/pixels',
        name: 'PixelsList',
        component: PixelsList,
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: '/add-pixel',
        name: 'AddPixel',
        component: AddPixel,
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: '/users',
        name: 'Users',
        component: Users,
        beforeEnter: (to, from, next) => {
            if (isAuthenticated() && isAdmin()) {
                next();
            } else if (isAuthenticated()) {
                ShowMessage("error", "Unauthorized");
                next('/403');
            } else {
                next('/');
            }
        },
    },
    {
        path: '/404',
        name: 'NotFound',
        component: PageNotFound
    },
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    // Check if the requested route exists
    if (router.hasRoute(to.name)) {
        next(); // Route exists, proceed with the navigation
    } else {
        next({name: 'NotFound'}); // Route not found, redirect to the not-found route
    }
});

export default router
